import React from "react";

const Home = () => (
    <div>
        <h2>
            Bienvenue sur le portail développeur de la plateforme partenaires Nexity.
        </h2>
        <p>
            Ce site a pour but d'accompagner nos partenaires dans l'utilisation des
            API Nexity pour le travail de notre offre immobilière.
        </p>
        <p>
            Nos API permettent aujourd'hui à un partenaire mandaté de :
            <li>rechercher parmi le catalogue de lots Nexity les lots disponibles ou non</li>
            <li>obtenir des informations détaillées sur un lot ou un programme immobilier</li>
            <li>provisionner des comptes conseiller sur la plateforme, pour le compte du partenaire mandaté</li>
            <li>utiliser une fonctionnalité passwordless de connexion à MonBookImmo</li>
            <p>Une présentation plus détaillée de cette plateforme est disponible sur l'onglet <a
                href={"/presentation"}>présentation</a>.</p>
        </p>
        <p>Pour commencer à utiliser ces API, rendez vous sur <a href={"/getting-started"}>bien débuter</a>. Nous avons décrit de la manière la plus didactique qui soit les possibilités de nos API, et la façon dont elles doivent être utilisées.
        </p>
    </div>
);

export default Home;
