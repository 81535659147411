import React from "react";
import {NavLink} from "react-router-dom";

const APILink = (props) => {
    let name = props.apiLinkData.apiName;
    let apiLink = props.apiLinkData.url;
    let link = props.apiLinkData.link;

    function handleClick() {
        props.updateDefinitionLink(apiLink);
    }

    return (
        <div className="api-link" onClick={() => handleClick()}>
            <NavLink to={link} activeClassName="selectedLink">
                {name}
            </NavLink>
        </div>
    );
};

export default APILink;
