import React from "react";
import APILink from "./APILinks";
import {NavLink} from "react-router-dom";
import "./Sidebar.scss";

const Sidebar = (props) => {
    let swaggerConfig = props.swaggerConfig;
    let apiLinks = [];

    if (!props.definitionList) {
        props.getSwaggerData(swaggerConfig);
    } else {
        for (let i = 0; i < props.definitionList.length; i++) {
            apiLinks.push(
                <APILink
                    key={i}
                    apiLinkData={props.definitionList[i]}
                    updateDefinitionLink={props.updateDefinitionLink}
                />
            );
        }
    }

    return (
        <div className="side-bar">
            <div className="side-bar-header">
                <div className="avatar">
                    <img
                        alt=""
                        src="https://upload.wikimedia.org/wikipedia/fr/thumb/7/7e/Nexity-logo.svg/512px-Nexity-logo.svg.png"
                    />
                </div>
                <h1>{swaggerConfig.appName}</h1>
                <h2>{swaggerConfig.subName}</h2>
            </div>
            <div className="side-bar-body">
                <NavLink to="/" exact activeClassName="selectedLink">
                    <h3>Accueil</h3>
                </NavLink>
            </div>
            <div className="side-bar-body">
                <NavLink to="/presentation" activeClassName="selectedLink">
                    <h3>Présentation</h3>
                </NavLink>
            </div>
            <div className="side-bar-body">
                <NavLink to="/getting-started" activeClassName="selectedLink">
                    <h3>Bien débuter</h3>
                </NavLink>
            </div>
            <div className="side-bar-body">
                    <h3>Documentation API</h3>
                    {apiLinks}
            </div>
            <div className="side-bar-body">
                <NavLink to="/changelog" activeClassName="selectedLink">
                    <h3>Changelog</h3>
                </NavLink>
            </div>
            <div className="side-bar-body">
                <NavLink to="/legal-notice" activeClassName="selectedLink">
                    <h3>Mentions légales</h3>
                </NavLink>
            </div>
        </div>
    );
};

export default Sidebar;
