import Home from "./Home";
import GettingStarted from "./GettingStarted";
import LegalNotice from "./LegalNotice";
import APIContainer from "./APIContainer";
import Presentation from "./Presentation";
import Changelog from "./Changelog";

const routes = [
    {
        path: "/",
        exact: true,
        main: Home,
    },
    {
        path: "/presentation",
        main: Presentation,
    },
    {
        path: "/getting-started",
        main: GettingStarted,
    },
    {
        path: "/v1",
        main: APIContainer,
    },
    {
        path: "/changelog",
        main: Changelog,
    },
    {
        path: "/legal-notice",
        main: LegalNotice,
    },
];

export default routes;
