import React from "react";
import PDFViewer from "./PDFViewer";
import PDFJS from "./backend/PDFJS";

const GettingStarted = () => (
    <div>
        <h2>
            Documentation des API Plateforme Partenaires
        </h2>
        <div class="pdf-viewer">
        <PDFViewer
            backend={PDFJS}
            src={process.env.PUBLIC_URL +"/documentation/PPA - API - Guide Utilisation - V1.4.pdf"}/>
        </div>
    </div>
);

export default GettingStarted;
