import React from "react";
import PDFViewer from "./PDFViewer";
import PDFJS from "./backend/PDFJS";

const LegalNotice = () => (
    <div>
      <h2>
        Mentions légales de la plateforme partenaires
      </h2>
      <div class="pdf-viewer">
        <PDFViewer
            backend={PDFJS}
            src={process.env.PUBLIC_URL +"/documentation/CGU_PPA VF.pdf"}/>
      </div>
    </div>
);

export default LegalNotice;
