import React from "react";

const Changelog = () => (
    <div>
        <h2>
            1.0.4
        </h2>
        <li>
            Mise à jour de la documentation d&#39;utilisation des API : correction d&#39;une erreur sur les valeurs du champ étage (floor) en sortie de l'API de recherche de lots.
        </li>
        <h2>
            1.0.2
        </h2>
        <li>
            Mise à jour de la documentation d&#39;utilisation des API : correction d&#39;une erreur sur le retour des API de recherche.
        </li>
        <h2>
            1.0.1
        </h2>
        <li>
            Mise à jour de la documentation d&#39;utilisation des API : nouveau mode d&#39;utilisation des jetons générés pour se connecter à MonBookImmo.
        </li>
        <li>
            Déploiement d&#39;un correctif pour pouvoir utiliser le lien authentifié sans renseigner de code programme ou de code lot.
        </li>
        <h2>
            1.0.0
        </h2>
        <li>
            Première version du portail Plateforme Partenaires.
        </li>
    </div>
);

export default Changelog;
