import React, {Component} from "react";
import {SwaggerUIBundle} from "swagger-ui-dist";
import "./App.scss";
import Sidebar from "./Sidebar";
import "../node_modules/swagger-ui-dist/swagger-ui.css";
import Config from "./swaggerConfig";
import {BrowserRouter as Router, Route} from "react-router-dom";
import routes from "./Routes";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            swaggerConfig: null,
            definitionList: null,
            definitionLink: "./api-box-ppa-box-swagger.json",
        };
        this.getSwaggerData = this.getSwaggerData.bind(this);
        this.updateDefinitionLink = this.updateDefinitionLink.bind(this);
    }

    updateDefinitionLink(newLink) {
        this.setState({
            definitionLink: newLink,
        });
    }

    componentDidUpdate() {
        SwaggerUIBundle({
            domNode: document.getElementById("api-data"),
            url: this.state.definitionLink,
        });
    }

    componentWillMount() {
        this.setState({
            swaggerConfig: Config,
        });
    }

    getSwaggerData(organization) {
        console.log(organization.apis);
        this.setState({
            definitionList: organization.apis,
        });
    }

    render() {
        return (
            <Router>
                <div className="App">
                    <Sidebar
                        swaggerConfig={this.state.swaggerConfig}
                        definitionList={this.state.definitionList}
                        updateDefinitionLink={this.updateDefinitionLink}
                        getSwaggerData={this.getSwaggerData}
                    />
                    <div className="data-container">
                        {routes.map((route) => (
                            <Route
                                key={route.path}
                                path={route.path}
                                exact={route.exact}
                                component={route.main}
                            />
                        ))}
                    </div>
                </div>
            </Router>
        );
    }
}

export default App;
