import React from "react";
import PDFViewer from "./PDFViewer";
import PDFJS from "./backend/PDFJS";

const Presentation = () => (
    <div>
      <h2>
        Présentation des API Plateforme Partenaires
      </h2>
      <div class="pdf-viewer">
        <PDFViewer
            backend={PDFJS}
            src={process.env.PUBLIC_URL +"/documentation/PPA-PRES-Presentation aux Partenaires-V1.0.pdf"}/>
      </div>
    </div>
);

export default Presentation;
